import React, { useState, useCallback, useContext, useEffect } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";

import { useTranslation } from "react-i18next";
import CredentialsHeader from "../components/continente-credentials/header/Header";
import UsernameInput from "../components/continente-credentials/username-input/UsernameInput";
import Group from "../components/test-page/group/Group";
import { User } from "../models/User";

import { testpageService } from "../services/TestPageService";
import { Idm } from "../models/test-page/Idm";
import { GetCardAssociationResponse } from "../models/test-page/GetCardAssociation";
import { LoyaltyCardCustomer } from "../models/test-page/LoyaltyCardCustomer";
import { UserContext } from "../contexts/continente-credentials/UserContext";
import CredentialsButton from "../components/continente-credentials/button/Button";
import { FormError } from "../models/continente-credentials/common/FormError";
import { WhiteListResult } from "../models/test-page/WhiteListResult";
import { UserProfile } from "../models/test-page/UserProfile";

type Form = User & FormError;

export default function Tests() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const [labelEmailOrPhoneNumberVisible, setLabelEmailOrPhoneNumberVisible] =
    useState<boolean>(false);

  const {
    handleSubmit,
    setValue,
    register,
    clearErrors,
    formState: { errors },
  } = useForm<Form>({ mode: "onSubmit", reValidateMode: "onSubmit" });
  const userContext = useContext(UserContext);

  const [update, setUpdate] = useState(false);

  const [dict, setMap] = useState<Array<Array<string>>>([["", ""]]);

  const [message, setMessage] = useState<string>("");

  const onSubmit: SubmitHandler<User> = async (data) => {
    setLoading(true);
    setMessage("");
    try {
      const response = await testpageService.getUserInfo(data.id);

      switch (response.kind) {
        case "success":
          isNumber(data.id.replace("+", ""))
            ? updateUserContext(phoneNumber)
            : updateUserContext(data.id);

          setMap(Object.entries(response.value));
          setUpdate(true);
          setLoading(false);

          break;

        case "error":
          setMessage(
            response.code === "300001"
              ? "Utilizador com id " + data.id + " não encontrado"
              : "Erro"
          );
          setLoading(false);
          setUpdate(false);
          break;
      }
    } catch (e) {
      setLoading(false);
      setUpdate(false);
      setMessage(t("common.unexpected_error"));
    }
  };

  function isNumber(input: string) {
    return !isNaN(Number(input)) && input.length > 2;
  }

  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("");

  const updateUserContext = (username: string) => {
    const isEmail = isNaN(Number(username.substring(1)));
    if (isEmail) {
      userContext.updateUser({
        ...userContext.user,
        phoneNumber: "",
        email: username,
        primaryContact: "email",
      });
    } else {
      userContext.updateUser({
        ...userContext.user,
        email: "",
        phoneNumber: username,
        phoneNumberCode: countryCode,
        primaryContact: "phoneNumber",
      });
    }
  };

  const handleEmailOrPhoneNumberChange = useCallback(
    (input: string, phoneNumberCode: string) => {
      clearErrors();
      const value = isNumber(input)
        ? `${
            userContext.user.phoneNumberCode !== ""
              ? userContext.user.phoneNumberCode
              : phoneNumberCode
          }${input}`
        : input;
      const cleanedValue = value.trim();

      setPhoneNumber(input.trim());
      setCountryCode(
        userContext.user.phoneNumberCode !== ""
          ? userContext.user.phoneNumberCode
          : phoneNumberCode
      );
      setValue("id", cleanedValue);
      setLabelEmailOrPhoneNumberVisible(cleanedValue.length > 0 ? true : false);
    },
    [clearErrors, setValue, userContext.user.phoneNumberCode]
  );

  useEffect(() => {
    const emailOrPhoneValidator = (register: UseFormRegister<Form>) =>
      register("id", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value:
            /(^\+?\d*$)|(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]*$)/,
          message: t("login_mobile_email.invalid_number_email_input"),
        },
      });
    emailOrPhoneValidator(register);
  }, [register, t]);

  let counter = 0;
  return (
    <>
      <div className="row justify-content-center">
        <CredentialsHeader
          title={t("Introduza o número de telemovel/email a procurar")}
          isTextAlignCenter
          showLoading={loading}
        />
      </div>
      {!loading ? (
        <form
          id="UserSearch"
          autoComplete="off"
          onSubmit={(e) => {
            handleSubmit(onSubmit)(e);
            clearErrors();
          }}
        >
          <div className="row d-flex justify-content-center input-padding-24">
            <UsernameInput
              type="email-phone"
              isFilled={labelEmailOrPhoneNumberVisible}
              isError={errors.id ? true : false}
              errorMsg={errors.id ? errors.id.message : ""}
              onChange={handleEmailOrPhoneNumberChange}
            />
          </div>
          <div className="row d-flex justify-content-center">
            <CredentialsButton
              text={t("login_mobile_email.button_next_message")}
              type="submit"
            />
          </div>
        </form>
      ) : undefined}

      {message === "" ? undefined : <Group message={message} />}
      {update
        ? dict != null
          ? dict?.map((key, index) => {
              counter++;
              let dic;
              console.log("Counter " + counter);
              switch (counter) {
                case 1:
                  dic = key[1] as unknown as Idm;

                  return (
                    <div key={index} className="d-flex justify-content-center">
                      <Group title={key[0]} idm={dic as Idm} />
                    </div>
                  );
                case 2:
                  dic = key[1] as unknown as GetCardAssociationResponse;

                  return (
                    <div className="d-flex justify-content-center">
                      <Group title={key[0]} cardAssociation={dic} />
                    </div>
                  );
                case 3:
                  dic = key[1] as unknown as LoyaltyCardCustomer;

                  return (
                    <div className="d-flex justify-content-center">
                      <Group title={key[0]} loyaltyCardCustomer={dic} />
                    </div>
                  );

                case 4:
                  dic = key[1] as unknown as WhiteListResult;

                  return (
                    <div className="d-flex justify-content-center">
                      <Group
                        title={key[0]}
                        whiteListResult={dic as WhiteListResult}
                      />
                    </div>
                  );
                case 5:
                  dic = key[1] as unknown as UserProfile;

                  return (
                    <div key={index} className="d-flex justify-content-center">
                      <Group title={key[0]} userProfile={dic as UserProfile} />
                    </div>
                  );
              }
            })
          : undefined
        : undefined}
    </>
  );
}
