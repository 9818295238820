import React from "react";
import { useFormContext } from "react-hook-form";

type InputProps = {
  text: string;
  type: "button" | "submit" | "reset";
  size?: "normal" | "large";
  isDisabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
};

export default function Button({
  text,
  type,
  size = "normal",
  isDisabled = false,
  onClick,
}: InputProps) {
  const form = useFormContext();

  const isSubmitting = form?.formState?.isSubmitting;
  const disabled = isDisabled || (type === "submit" && isSubmitting);

  return (
    <button
      disabled={disabled}
      type={type}
      className={"mt-4 buttons-primary-" + size}
      onClick={onClick}
    >
      {!disabled ? (
        <div className="buttons-primary-normal-text">{text}</div>
      ) : (
        <div className="buttons-primary-normal-text-disabled">{text}</div>
      )}
    </button>
  );
}
