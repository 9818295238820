import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Menu() {
  const history = useHistory();
  const clickTelaIFrame = () => history.push("/iframe");
  const clickTela1 = () => history.push("/user-register?clientId=1");

  const clickTela2 = () => history.push("/phonenumber/registration/verify-otp");
  const clickTela3 = () =>
    history.push("/phonenumber/registration/email-association");
  const clickTela6 = () =>
    history.push("/phonenumber/registration/set-password");
  const clickTela25 = () =>
    history.push("/phonenumber/registration/email-validated");
  const clickTela22 = () =>
    history.push("/phonenumber/registration/validate-password");

  const clickTela5 = () => history.push("/phonenumber/login/validate-password");
  const clickTela34 = () =>
    history.push("/phonenumber/login/email-association");
  const clickTela36 = () => history.push("/phonenumber/login/verify-pin");
  const clickTela37 = () => history.push("/phonenumber/login/verify-otp");
  const clickTela371 = () =>
    history.push("/phonenumber/login/account-association-options");

  const clickTela33 = () => history.push("/email/login/validate-password");
  const clickTela39 = () =>
    history.push("/email/login/phonenumber-association");
  const clickTela40 = () => history.push("/email/login/verify-otp");

  const clickTela7 = () => history.push("/AccountMergeOptions");
  const clickTela8 = () => history.push("/EmailAlreadyExists");
  const clickTela9 = () => history.push("/Login");
  const clickTela10 = () => history.push("/SocialLogin");
  const clickTela11 = () => history.push("/SocialLoginConsent");

  const clickTela12 = () => history.push("/web/registration/user");
  const clickTela13 = () => history.push("/web/registration/verify-otp");
  const clickTela14 = () =>
    history.push("/web/registration/loyaltycard/connect");
  const clickTela15 = () =>
    history.push("/web/registration/loyaltycard/connect-successfully");
  const clickTela16 = () => history.push("/web/registration/add-card");
  const clickTela17 = () => history.push("/web/registration/login");
  const clickTela18 = () =>
    history.push("/web/registration/loyaltycard/verify-otp");

  const clickTela20 = () => history.push("/email/registration/verify-otp");
  const clickTela21 = () =>
    history.push("/email/registration/phonenumber-verify-otp");
  const clickTela23 = () =>
    history.push("/email/registration/phonenumber-association");
  const clickTela26 = () => history.push("/email/registration/set-password");
  const clickTela28 = () => history.push("/email/registration/verify-pin");
  const clickTela29 = () =>
    history.push("/email/registration/pin-set-password");
  const clickTela291 = () => history.push("/email/registration/recover-pin");

  const clickTela30 = () => history.push("/continentecredentialsfaq");
  const clickTela31 = () => history.push("/terms-and-conditions");
  const clickTela32 = () => history.push("/privacy-politics");

  const clickTela50 = () => history.push("/card/continente-card-creation");
  const clickTela51 = () => history.push("/card/call-center-loyalty-card");
  const clickTela52 = () => history.push("/card/identifier-verification");
  const clickTela53 = () =>
    history.push("/card/loyalty-card-association-success");

  const clickTela54 = () => history.push("/site-credentials");
  const clickTela55 = () =>
    history.push("/site-credentials/password/definition/password-definition");
  const clickTela56 = () =>
    history.push("/site-credentials/phonenumber/duplicated-information");
  const clickTela57 = () => history.push("/site-credentials/faq");

  const clickTelaContactCenter = () => history.push("/contact-center");

  const { t } = useTranslation();

  const showOrHidePhoneRegistrationPages = (): void => {
    const x = document.getElementById("divPhonenumberRegistration");
    if (x != null)
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
  };

  const showOrHidePhoneLoginPages = (): void => {
    const x = document.getElementById("divPhonenumberLogin");
    if (x != null)
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
  };

  const showOrHideEmailRegistrationPages = (): void => {
    const x = document.getElementById("divEmailRegistration");
    if (x != null)
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
  };

  const showOrHideEmailLoginPages = (): void => {
    const x = document.getElementById("divEmailLogin");
    if (x != null)
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
  };

  const showOrHidedivBottomSheetPages = (): void => {
    const x = document.getElementById("divBottomSheet");
    if (x != null)
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
  };

  const showOrHidedivWebRegistrationPages = (): void => {
    const x = document.getElementById("divWebRegistration");
    if (x != null)
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
  };

  const showOrHidedivSocialLoginPages = (): void => {
    const x = document.getElementById("divSocialLogin");
    if (x != null)
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
  };

  const showOrHidedivLoyaltyCardPages = (): void => {
    const x = document.getElementById("divLoyaltyCard");
    if (x != null)
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
  };

  const showOrHidedivSiteCredentials = (): void => {
    const x = document.getElementById("divSiteCredentials");
    if (x != null)
      if (x.style.display === "none") {
        x.style.display = "block";
      } else {
        x.style.display = "none";
      }
  };

  return (
    <>
      <div>
        <h3>Credenciais Continente</h3>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela1}
        >
          <div className="buttons-primary-normal-text">User Register</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTelaIFrame}
        >
          <div className="buttons-primary-normal-text">IFrame</div>
        </button>
      </div>
      <br />
      <br />
      <br />
      <h3
        onClick={showOrHidePhoneRegistrationPages}
        style={{ cursor: "pointer" }}
      >
        Phonenumber Registration
      </h3>
      <div
        id="divPhonenumberRegistration"
        style={{ display: "none", background: "#F5F5F5" }}
      >
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela2}
        >
          <div className="buttons-primary-normal-text">Verify OTP</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela3}
        >
          <div className="buttons-primary-normal-text">Email Association</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela6}
        >
          <div className="buttons-primary-normal-text">Set Password</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela22}
        >
          <div className="buttons-primary-normal-text">Validate Password</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela25}
        >
          <div className="buttons-primary-normal-text">Email Validated</div>
        </button>
      </div>

      <h3 onClick={showOrHidePhoneLoginPages} style={{ cursor: "pointer" }}>
        Phone Login
      </h3>
      <div
        id="divPhonenumberLogin"
        style={{ display: "none", background: "#F5F5F5" }}
      >
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela5}
        >
          <div className="buttons-primary-normal-text">Validate Password</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela34}
        >
          <div className="buttons-primary-normal-text">Email Association</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela36}
        >
          <div className="buttons-primary-normal-text">Verify Pin</div>
        </button>

        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela37}
        >
          <div className="buttons-primary-normal-text">Verify OTP</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela371}
        >
          <div className="buttons-primary-normal-text">
            Account Association Options
          </div>
        </button>
      </div>
      <h3
        onClick={showOrHideEmailRegistrationPages}
        style={{ cursor: "pointer" }}
      >
        Email Registration
      </h3>
      <div
        id="divEmailRegistration"
        style={{ display: "none", background: "#F5F5F5" }}
      >
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela20}
        >
          <div className="buttons-primary-normal-text">Email Verify OTP</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela21}
        >
          <div className="buttons-primary-normal-text">
            Phonenumber Verify OTP
          </div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela26}
        >
          <div className="buttons-primary-normal-text">
            (Email) Set Password
          </div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela28}
        >
          <div className="buttons-primary-normal-text">Verify PIN</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela29}
        >
          <div className="buttons-primary-normal-text">
            (Email) Set Pin Password
          </div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela291}
        >
          <div className="buttons-primary-normal-text">Recover PIN</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela23}
        >
          <div className="buttons-primary-normal-text">
            Phone Number Association (Emailflow)
          </div>
        </button>

        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTelaContactCenter}
        >
          <div className="buttons-primary-normal-text">Contact Center</div>
        </button>
      </div>

      <h3 onClick={showOrHideEmailLoginPages} style={{ cursor: "pointer" }}>
        Email Login
      </h3>
      <div
        id="divEmailLogin"
        style={{ display: "none", background: "#F5F5F5" }}
      >
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela33}
        >
          <div className="buttons-primary-normal-text">Validate Password</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela39}
        >
          <div className="buttons-primary-normal-text">Phone Association</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela40}
        >
          <div className="buttons-primary-normal-text">Verify OTP</div>
        </button>
      </div>

      <h3 onClick={showOrHidedivBottomSheetPages} style={{ cursor: "pointer" }}>
        Bottom Sheet
      </h3>
      <div
        id="divBottomSheet"
        style={{ display: "none", background: "#F5F5F5" }}
      >
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela30}
        >
          <div className="buttons-primary-normal-text">
            ContinenteCredentials Faq
          </div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela31}
        >
          <div className="buttons-primary-normal-text">
            Terms And Conditions
          </div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela32}
        >
          <div className="buttons-primary-normal-text">Privacy Politics</div>
        </button>
      </div>

      <h3
        onClick={showOrHidedivWebRegistrationPages}
        style={{ cursor: "pointer" }}
      >
        Web Registration
      </h3>
      <div
        id="divWebRegistration"
        style={{ display: "none", background: "#F5F5F5" }}
      >
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela14}
        >
          <div className="buttons-primary-normal-text">
            Loyalty Card Connect
          </div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela15}
        >
          <div className="buttons-primary-normal-text">
            Loyalty Card Connect Successfully
          </div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela12}
        >
          <div className="buttons-primary-normal-text">User Register</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela13}
        >
          <div className="buttons-primary-normal-text">Verify OTP</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela16}
        >
          <div className="buttons-primary-normal-text">Add Card</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela17}
        >
          <div className="buttons-primary-normal-text">Login</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela18}
        >
          <div className="buttons-primary-normal-text">
            Loyalty Card Verify OTP
          </div>
        </button>
      </div>

      <h3 onClick={showOrHidedivSocialLoginPages} style={{ cursor: "pointer" }}>
        Social Login
      </h3>
      <div
        id="divSocialLogin"
        style={{ display: "none", background: "#F5F5F5" }}
      >
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela7}
        >
          <div className="buttons-primary-normal-text">AccountMergeOptions</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela8}
        >
          <div className="buttons-primary-normal-text">EmailAlreadyExists</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela9}
        >
          <div className="buttons-primary-normal-text">Login</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela10}
        >
          <div className="buttons-primary-normal-text">Social Login</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela11}
        ></button>
      </div>

      <h3 onClick={showOrHidedivLoyaltyCardPages} style={{ cursor: "pointer" }}>
        Loyalty Card
      </h3>
      <div
        id="divLoyaltyCard"
        style={{ display: "none", background: "#F5F5F5" }}
      >
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela50}
        >
          <div className="buttons-primary-normal-text">Add Card</div>
        </button>

        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela51}
        >
          <div className="buttons-primary-normal-text">
            Call Center Loyalty Card
          </div>
        </button>

        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela52}
        >
          <div className="buttons-primary-normal-text">
            Identifier Verification
          </div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela53}
        >
          <div className="buttons-primary-normal-text">
            Loyalty Card Association Success
          </div>
        </button>
      </div>

      <h3 onClick={showOrHidedivSiteCredentials} style={{ cursor: "pointer" }}>
        Site Credentials
      </h3>
      <div
        id="divSiteCredentials"
        style={{ display: "none", background: "#F5F5F5" }}
      >
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela54}
        >
          <div className="buttons-primary-normal-text">Home</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela55}
        >
          <div className="buttons-primary-normal-text">Password Definition</div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela56}
        >
          <div className="buttons-primary-normal-text">
            Duplicated PhoneNumber Association
          </div>
        </button>
        <button
          type="button"
          className="buttons-primary-normal"
          onClick={clickTela57}
        >
          <div className="buttons-primary-normal-text">Faq</div>
        </button>
      </div>

      <div className="language_div">
        <h4>language: {t("language")}</h4>
      </div>
    </>
  );
}
