import React, { useEffect, useState, useContext } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";

import CredentialsFooter from "../../../../components/continente-credentials/footer/Footer";
import CredentialsHeader from "../../../../components/continente-credentials/header/Header";
import CredentialsForm from "../../../../components/continente-credentials/Form/Form";
import CredentialsOtpInput from "../../../../components/continente-credentials/otp-input/OtpInput";

import { UserSignInResponse } from "../../../../models/continente-credentials/mobile/UserSignInResponse";
import { OtpValidation } from "../../../../models/continente-credentials/mobile/OtpValidation";
import { FormError } from "../../../../models/continente-credentials/common/FormError";

import { otpValidationService } from "../../../../services/continente-credentials/mobile/OtpValidationService";

import { useTranslation } from "react-i18next";
import MantraButton from "../../../../components/continente-credentials/mantra-button/MantraButton";

type Form = OtpValidation & FormError;

export default function VerifyOtp() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const location = useLocation<UserSignInResponse>();
  const phoneNumber =
    location.state != null ? location.state.properties.phoneNumber : "";
  const jwtToken =
    location.state != null ? location.state.properties.jwtToken : "";

  const goToFaq = (anchor?: string) => {
    if (anchor) anchor = "#" + anchor;
    history.push("/continente-credentials/faq" + anchor);
  };
  const ANCHOR_NEED_HELP = "";

  const formState = useForm<Form>({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = formState;

  const handleOTPChange = (value: string) => {
    clearErrors();
    setValue("code", value.trim());
  };

  const onSubmit: SubmitHandler<OtpValidation> = async (data) => {
    setLoading(true);
    try {
      const response = await otpValidationService.otpValidation(
        {
          code: data.code,
          phoneNumber: phoneNumber,
        },
        location.state != null ? location.state.nextStep : ""
      );

      switch (response.kind) {
        case "success":
          history.push(response.value.nextStep.substring(4), response.value);

          break;
        case "error":
          if (response.code === "100004") {
            setError("code", {
              message: t("otp_validation.invalid_code"),
            });
            break;
          } else if (response.code === "100015") {
            setError("submitError", {
              message: t("otp_validation.invalid_nonce"),
            });
            break;
          } else if (response.code === "100016") {
            setError("submitError", {
              message: t("otp_validation.invalid_nonce"),
            });
            break;
          }
          setError("submitError", { message: t("common.unexpected_error") });
          break;

        default:
          setError("submitError", { message: t("common.unexpected_error") });

          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: t("common.unexpected_error") });
      setLoading(false);
    }
  };

  useEffect(() => {
    const otpValidator = (register: UseFormRegister<Form>) =>
      register("code", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value: /^[0-9]*$/,
          message: t("otp_validation.code_must_be_numeric"),
        },
      });
    otpValidator(register);
  }, [register, t]);

  return (
    <>
      <CredentialsForm
        id="VerifyOtp"
        form={formState}
        autoComplete="off"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row justify-content-center">
          <CredentialsHeader
            title={t("otp_validation.header_title_mobile")}
            subtitle={t("otp_validation.header_message") + " " + phoneNumber}
            showLoading={loading}
            previousStepBackXTimes={1}
          />
        </div>
        <div className="row justify-content-center">
          <CredentialsOtpInput
            isEmail={false}
            isError={errors.code ? true : false}
            errorMsg={errors.code ? errors.code.message : ""}
            onChange={handleOTPChange}
            jwtToken={jwtToken}
            onResendClick={setLoading}
          />
        </div>
        <div className="row justify-content-center form-error-message-copy2">
          {errors.submitError && (
            <div
              dangerouslySetInnerHTML={{
                __html: errors.submitError.message
                  ? errors.submitError.message.toString()
                  : "",
              }}
            />
          )}
        </div>
        <div className="row d-flex justify-content-center">
          <MantraButton
            text={t("otp_validation.button_next_message")}
            type="submit"
          />
        </div>
        <div className="contextual-information-header text-align-center">
          <span
            className="verification-resend-code link"
            onClick={() => goToFaq(ANCHOR_NEED_HELP)}
          >
            {t("common.need_help")}
          </span>
        </div>
        <div className="row d-flex flex-column">
          <CredentialsFooter />
        </div>
      </CredentialsForm>
    </>
  );
}
