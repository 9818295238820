import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler, UseFormRegister } from "react-hook-form";
import { FormError } from "../../../models/continente-credentials/common/FormError";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CredentialsButton from "../../../components/continente-credentials/button/Button";
import CredentialsHeader from "../../../components/continente-credentials/header/Header";
import UsernameInput from "../../../components/continente-credentials/username-input/UsernameInput";
import { userService } from "../../../services/continente-credentials/tests/UserService";
import { DeleteUser } from "../../../models/continente-credentials/tests/DeleteUser";
import alertCircle from "../../../assets/images/alert-circle.svg";

type Form = DeleteUser & FormError;

export default function DeleteUserPage() {
  const history = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [labelEmailOrPhoneNumberVisible, setLabelEmailOrPhoneNumberVisible] =
    useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<Form>({ mode: "onSubmit", reValidateMode: "onSubmit" });

  const handleEmailOrPhoneNumberChange = (
    input: string,
    phoneNumberCode: string
  ): void => {
    clearErrors();
    const value = isNumber(input) ? `${phoneNumberCode}${input}` : input;
    const cleanedValue = value.trim();
    setValue("username", cleanedValue.trim());
    setLabelEmailOrPhoneNumberVisible(input.length > 0 ? true : false);
  };

  function isNumber(input: string) {
    return !isNaN(Number(input)) && input.length > 2;
  }

  const onSubmit: SubmitHandler<DeleteUser> = async (data) => {
    setLoading(true);
    try {
      const response = await userService.DeleteUser(data);
      switch (response.kind) {
        case "success":
          history.push("/iframe");

          break;
        default:
          setError("submitError", { message: "Erro eliminando o utilizador" });

          break;
      }

      setLoading(false);
    } catch (e) {
      setError("submitError", { message: "Erro eliminando o utilizador" });
      setLoading(false);
    }
  };

  useEffect(() => {
    const emailOrPhoneValidator = (register: UseFormRegister<Form>) =>
      register("username", {
        required: {
          value: true,
          message: t("common.required_field"),
        },
        pattern: {
          value:
            /(^\+?\d*$)|(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]*$)/,
          message: t("login_mobile_email.invalid_number_email_input"),
        },
      });
    emailOrPhoneValidator(register);
  }, [register, t]);

  return (
    <>
      <form
        id="RegistrationLogin"
        autoComplete="off"
        onSubmit={(e) => {
          clearErrors();
          handleSubmit(onSubmit)(e);
        }}
      >
        <div className="row d-flex justify-content-center">
          <CredentialsHeader
            isTextAlignCenter={true}
            title={t("login_password.header_title")}
            subtitle={"Delete do User"}
            showLoading={loading}
          />
        </div>
        <div className="row d-flex justify-content-center">
          <UsernameInput
            type="email-phone"
            isFilled={labelEmailOrPhoneNumberVisible}
            isError={errors.username ? true : false}
            errorMsg={errors.username ? errors.username.message : ""}
            onChange={handleEmailOrPhoneNumberChange}
          />
        </div>
        <div className="row d-flex justify-content-center">
          <div className="d-inline-flex justify-content-center errorDiv">
            {errors.submitError && (
              <img src={alertCircle} alt="" className="alert-cicle" />
            )}
            <span className="errorLabel">{errors.submitError?.message}</span>
          </div>
        </div>
        <div className="row d-flex justify-content-center">
          <CredentialsButton
            text={t("login_password.button_next_message")}
            type="submit"
          />
        </div>
      </form>
    </>
  );
}
